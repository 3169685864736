import { cloneDeep, forEach, has, set } from 'lodash'
import { PermissionAction } from '../models/Permission'
import { initPermissionList } from '../redux/reducers/permissionSlice'

export const mapPermissionList = (permissions?: PermissionAction[]) => {
  const permissionList = cloneDeep(initPermissionList)
  const isSuperAdmin = !permissions
  if (!isSuperAdmin) {
    // Reset all permissions to false
    forEach(permissionList, (permissions) => {
      forEach(permissions, (value, key) => {
        set(permissions, key, false)
      })
    })
    // Set permissions of the user to true
    forEach(permissions, ({ action, entity }) => {
      if (has(permissionList, [entity, action])) {
        set(permissionList, [entity, action], true)
      }
    })
  }
  return permissionList
}
