import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { PermissionList } from '../../models/Permission'

interface PermissionState {
  permissionList: PermissionList
}

export const initPermissionList: PermissionList = {
  stock: {
    read: true,
    update: true,
  },
  attendu: {
    read: true,
    create: true,
    update: true,
    delete: true,
    import: true,
    export: true,
  },
  commande: {
    read: true,
    create: true,
    update: true,
    delete: true,
    import: true,
    export: true,
  },
  reference: {
    read: true,
    create: true,
    update: true,
    delete: true,
    import: true,
    export: true,
  },
}

const initialState: PermissionState = {
  permissionList: initPermissionList,
}

const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    setPermissions(state, action: PayloadAction<any>) {
      state.permissionList = action.payload.permissionList
    },
  },
})

const permissionReducer = permissionSlice.reducer

export default permissionReducer

export const { setPermissions } = permissionSlice.actions
