import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../app/store'

const usePermissions = () => {
  const { permissionList } = useSelector((state: RootState) => state.permission)

  const canAccessStock = permissionList.stock.read
  const canAccessAttendu = permissionList.attendu.read
  const canAccessCommande = permissionList.commande.read

  const canCreateAttendu = permissionList.attendu.create
  const canCreateCommande = permissionList.commande.create
  const canCreateReference = permissionList.reference.create

  const canUpdateStock = permissionList.stock.update
  const canUpdateAttendu = permissionList.attendu.update
  const canUpdateCommande = permissionList.commande.update

  const canImportReference = permissionList.reference.import
  const canImportAttendu = permissionList.attendu.import
  const canImportCommande = permissionList.commande.import

  return {
    canAccessStock,
    canAccessAttendu,
    canAccessCommande,
    canCreateReference,
    canCreateAttendu,
    canCreateCommande,
    canUpdateStock,
    canUpdateAttendu,
    canUpdateCommande,
    canImportReference,
    canImportAttendu,
    canImportCommande,
  }
}

export default usePermissions
